/*** Style fixes ***/

@grid-gutter-horizontal: @theme_global-padding; // allow to spread nested grid to full width of any container with padding
@grid-gutter-large-horizontal: @theme_global-padding;

@base-body-font-family: Roboto, Arial, sans-serif;
@base-heading-font-family: Roboto, Arial, sans-serif;
@navbar-nav-font-family: Roboto, Arial, sans-serif;

@global-color: #292929; // Darker than default in theme - and therefore more contrast
@global-dark-color: #363636;
@base-heading-color: #484848;
@global-muted-color: #848484;
@theme_sidebar-dark-text-muted-color: darken(@global-muted-color, 10%);
@base-selection-background: #d5f2f6; // The same with selection background
@global-border: #dadada;
@global-light-background: rgba(120, 100, 80, 0.05); // was rgba(65, 65, 70, 0.035);
//@table-striped-background:

@global-background: #fffefc; //#fefdfb; // #fffefc;
@datepicker-table-active-background: @global-default-background;
@panel-hover-hover-background: @panel-box-primary-background;

@global-font-size: 1rem;
@global-line-height: 1.5rem; // was 1.625rem
@pagination-padding-vertical: 0.1875*@global-font-size; // 3px
@pagination-padding-horizontal: 0.3125*@global-font-size; // 5px

@form-stacked-font-weight: normal;
@nav-header-font-weight: 500;
@description-list-line-font-weight: 500;
@badge-font-weight: 500;

@form-icon-width: 36px;
@form-icon-font-size: 16px;

/*** Navbar on small screens ***/

@navbar-toggle-font-size: 28px;

.uk-navbar-center {
    max-width: 65% !important;
}

.uk-navbar-brand {
	padding: 0 20px;
}

/*** INS tag used by Google AdSense ***/

@base-ins-background: transparent;
@base-ins-color: #000;

/*** Wider page layout ***/

@media (min-width: @breakpoint-large) {
    .uk-container {
        padding: 0; // No main container padding on desktops - use it to wider content
    }
}
@media (min-width: @breakpoint-medium) {
    .tm-main {
        &.uk-width-medium-7-10 { width: 71%; }
        &.uk-push-3-10 { left: 29%; }
    }
    .tm-sidebar-a {
        &.uk-width-medium-3-10 { width: 29%; }
        &.uk-pull-7-10 { left: -71%; }
    }
}

/*** Sidebar and footer ***/

.tm-sidebar-dark input {
    background: #fcfcfc !important;
}

.tm-footer {
    a {
        color: @base-body-color;
        &:hover {
            color: @base-link-hover-color;
        }
    }
}

.tm-sidebar-a-left {
    .tm-block-alt,
    .tm-sidebar-dark {
        border-left: none !important; // Fixes double left border of sidebar pushed before main column
    }
}

/*** Missed functionality ***/

@breakpoint-mini: 320px;

/** Visibility (avoid setting display to `block` so it works also with `inline-block` and `table`) **/

/* Desktop and bigger */
@media (min-width: @breakpoint-large) {

    .uk-visible-mini { display: none !important; }

}

/* Tablets portrait */
@media (min-width: @breakpoint-medium) and (max-width: @breakpoint-medium-max) {

    .uk-visible-mini { display: none !important; }

}

/* Phone landscape and smaller*/
@media (min-width: @breakpoint-small) and (max-width: @breakpoint-small-max) {

    .uk-visible-mini { display: none !important; }
    .uk-visible-medium { display: none !important; }
    .uk-visible-large { display: none !important; }
    .uk-hidden-small { display: none !important; }

}

/* Extra-small */
@media (max-width: @breakpoint-mini-max) {

    .uk-visible-small { display: none !important; }
    .uk-visible-medium { display: none !important; }
    .uk-visible-large { display: none !important; }
    .uk-hidden-mini { display: none !important; }

}

/** Responsive titles on small screens **/

@media (max-width: @breakpoint-small-max) {

    h1, .uk-h1 {
        font-size: @base-h2-font-size;
        line-height: @base-h2-line-height;
    }

    h2, .uk-h2 {
        font-size: @base-h3-font-size;
        line-height: @base-h3-line-height;
    }

    h3, .uk-h3 {
        font-size: @base-h4-font-size;
        line-height: @base-h4-line-height;
    }

    h4, .uk-h4 {
        font-size: @base-h5-font-size;
        line-height: @base-h5-line-height;
    }

}

/** Content padding on medium and small screens **/

@media (max-width: @breakpoint-small-max) {

    .uk-article {
        padding: 15px !important;
    }

}
@media (max-width: @breakpoint-medium-max) {

    .uk-panel-box {
        padding: 20px !important;
        &.uk-padding-top-remove {
            padding-top: 0 !important;;
        }
        &.uk-padding-bottom-remove {
            padding-bottom: 0 !important;;
        }
    }

}

/*** Modal dialogs ***/

.hook-modal-dialog() {
    border-radius: @global-border-radius;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

@media (max-width: @breakpoint-mini-max) {

    .uk-modal-dialog {
        padding: 20px !important;
    }

}

/*** Search fields in nav ***/

.uk-navbar {
    .uk-search-field {
        transition-property: width !important;
    }
    .uk-search-field:hover,
    .uk-search-field:focus,
    .uk-search.uk-active .uk-search-field {
        width: @search-focus-width !important;
        padding: 0 0 0 @search-padding !important;
        border-bottom: @search-border-width solid @global-border !important;
        &:-ms-input-placeholder { color: @offcanvas-search-placeholder-color !important; }
        &::-moz-placeholder { color: @offcanvas-search-placeholder-color; }
        &::-webkit-input-placeholder { color: @offcanvas-search-placeholder-color; }
    }
}

@search-focus-width-xlarge: @search-focus-width;

@media (min-width: @breakpoint-xlarge) {

    .uk-navbar {
        .uk-search-field:hover,
        .uk-search-field:focus,
        .uk-search.uk-active .uk-search-field {
            width: @search-focus-width-xlarge !important;
        }
    }
}

/* Old IE and NoScript alert */

.wpf-ie-noscript-warn {
    padding: 20px;
    font-size: 20px;
}
