// Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

//
// Component: Base
//
// ========================================================================


// Variables
// ========================================================================

@base-body-background:                          @global-background;
@base-body-font-family:                         'Open Sans';
@base-body-font-size:                           @global-font-size;
@base-body-line-height:                         @global-line-height;
@base-body-color:                               @global-color;
@base-link-color:                               @global-link-color;
@base-link-hover-color:                         @global-link-hover-color;
@base-ins-background:                           fade(@global-light-background, 5%);
@base-ins-color:                                @global-color;
@base-mark-background:                          #fadf46;
@base-mark-color:                               @global-color;
@base-selection-background:                     lighten(#96dfe6, 20%);
@base-selection-color:                          @global-color;
@base-margin-vertical:                          @global-margin;
@base-heading-font-family:                      'Open Sans';
@base-heading-color:                            @global-dark-color;
@base-heading-margin-top:                       @global-margin-large;
@base-h1-font-size:                             @global-font-size * 2.25; //** Rem-based sizes - 36px in base size
@base-h1-line-height:                           1.25; //** Relative to corresponding font size
@base-h2-font-size:                             @global-font-size * 1.75; //** Rem-based sizes - 28px in base size
@base-h2-line-height:                           1.25; //** Relative to corresponding font size
@base-h3-font-size:                             @global-font-size * 1.5; //** Rem-based sizes - 24px in base size
@base-h3-line-height:                           1.35; //** Relative to corresponding font size
@base-h4-font-size:                             @global-font-size * 1.25; //** Rem-based sizes - 20px in base size
@base-h4-line-height:                           1.35; //** Relative to corresponding font size
@base-h5-font-size:                             @global-font-size * 1.125; //** Rem-based sizes - 18px in base size
@base-h5-line-height:                           1.35; //** Relative to corresponding font size
@base-h6-font-size:                             @global-font-size * 1.125; //** Rem-based sizes - 18px in base size
@base-h6-line-height:                           1.35; //** Relative to corresponding font size
@base-hr-border:                                @global-border;
@base-blockquote-border:                        @global-border;
@base-blockquote-small-color:                   @global-muted-color;
@base-blockquote-font-size:                     @global-font-size * 1.25; //** Rem-based sizes - 20px in base size
@base-blockquote-line-height:                   1.625; //** Relative to corresponding font size
@base-code-font-size:                           @global-font-size * 0.875; //** Rem-based sizes - 14px in base size
@base-pre-background:                           @global-light-background;
@base-pre-color:                                @global-color;
@base-pre-font-size:                            @global-font-size * 0.875; //** Rem-based sizes - 14px in base size
@base-pre-line-height:                          1.625; //** Relative to corresponding font size

//
// New
//

@base-heading-margin-bottom:                    20px;
@base-pre-border:                               fadein(@global-light-background, 5%);


// Body
// ==========================================================================

.hook-base-body() {}


// Headings
// ==========================================================================

h1, h2, h3, h4, h5, h6 { margin-bottom: @base-heading-margin-bottom; }


// Code and preformatted text
// ==========================================================================

.hook-base-code() {}

.hook-base-code-reset() {}

.hook-base-pre() { border: 1px solid @base-pre-border; }

// Miscellaneous
// ========================================================================

.hook-base-misc() {

    // Text-level semantics
    // ==========================================================================

    //
    // Links
    //

    a {
        -webkit-transition: background-color .15s ease-in-out, color .15s ease-in-out;
        transition: background-color .15s ease-in-out, color .15s ease-in-out;
    }

    a:hover { text-decoration: none; }

    a:active {
        -webkit-transition: background-color .03s ease-in, color .03s ease-in;
        transition: background-color .03s ease-in, color .03s ease-in;
    }

}