/** Based on Vida theme with Green style */

@import "../playtform-web/themes/vida/less/theme.less";         //** 1. Base theme
@import "../playtform-web/themes/vida/styles/green/style.less"; //** 2. Theme style
@import "../playtform-web/themes/vida/patches.less";            //** 3. Theme patches
@import "../playtform-web/themes/shared.less";                  //** 4. Shared styles

/* Centered title */

@media (max-width: @breakpoint-small-max) {

	h2.ua-centered-title {
		margin-bottom: 10px;
		text-align: center;
	}

}

/* Welcome screen */

.ua-welcome-screen .ua-buttons .uk-button {
	min-width: 200px;
}

@media (max-width: @breakpoint-small-max) {

	.ua-welcome-screen .ua-buttons {
		margin-top: 30px !important;
	}

}

/* Login screen */

.ua-social-providers {
	padding-left: 0;
	padding-right: 0;
	ul {
		list-style-type: none;
		padding: 0;
		li {
			display: inline-block;
			margin: 0 10px 12px 0;
		}
		img {
			width: 48px;
			height: 48px;
			opacity: 0.7;
			transition-duration: 0.3s;
		}
		a:hover img {
			opacity: 1;
		}
	}
}

@media (max-width: @breakpoint-medium-max) {

	.ua-login-screen {
		.uk-panel-box,
		.ua-social-providers {
			padding: 20px;
		}
	}

}

/* Signup screen */

@media (max-width: @breakpoint-small-max) {

	.ua-signup-screen {
		.uk-panel-box {
			padding: 20px;
		}
	}

}

/* Profile screen */

.ua-profile-avatar {
	max-width: 200px;
	text-align: center;
	margin: 0 auto;
	&>img,
	&>i {
		padding: 8px;
	}
	&>i {
		font-size: 120px;
	}
}

@media (max-width: @breakpoint-medium-max) {
	.ua-profile-avatar>i {
		font-size: 80px;
	}
}
@media (max-width: @breakpoint-mini-max) {
	.ua-profile-avatar>i {
		font-size: 120px;
	}
}

.ua-linked-accounts {
	list-style-type: none;
	padding: 0;
	li {
		display: inline-block;
		margin-right: 20px;
	}
}

.ua-profile-buttons {
	.uk-button {
		margin-right: 20px;
	}
}

@media (max-width: @breakpoint-mini-max) {

	.ua-profile-buttons {
		text-align: center;
		.uk-button {
			margin-left: 10px;
			margin-right: 10px;
		}
	}

}
