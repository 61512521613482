// Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

//
// Component: Search
//
// ========================================================================


// Variables
// ========================================================================

@search-width:                                  @search-padding;
@search-height:                                 @global-height;
@search-color:                                  @global-color;
@search-placeholder-color:                      rgba(0,0,0,0);
@search-icon-size:                              20px;
@search-icon-color:                             @global-color;

@dropdown-search-background:                    @global-background;
@dropdown-search-color:                         @global-color;
@dropdown-search-navbar-margin-top:             15px;
@dropdown-search-navbar-margin-right:           -15px;

@nav-search-color:                              @global-muted-color;
@nav-search-active-background:                  @global-transparent-background;
@nav-search-active-color:                       @global-dark-color;
@nav-search-header-color:                       darken(@global-color, 5%);
@nav-search-divider-border:                     @global-border;
@nav-search-nested-color:                       @global-muted-color;
@nav-search-nested-hover-color:                 @global-dark-color;

@offcanvas-search-margin:                       0;
@offcanvas-search-background:                   rgba(0,0,0,0);
@offcanvas-search-color:                        @global-color;

//
// New
//

@nav-search-padding-vertical:                   15px;
@nav-search-hover-color:                        @global-dark-color;
@nav-search-border:                             @global-border;

@offcanvas-search-field-border:                 @global-border;


// Component
// ==========================================================================

.hook-search() {}

.hook-search-icon() {}


// Sub-object `uk-search-field`
// ==========================================================================

.hook-search-field() {
    position: relative;
    z-index: 1;
    padding: 0;
    cursor: pointer;
}

.hook-search-field-focus() {}


// Modifier: `uk-dropdown-search`
// ==========================================================================

.hook-dropdown-search() {}


// Modifier `uk-nav-search`
// ==========================================================================

//
// Items
//

.hook-nav-search() {

    border-top: 1px solid @nav-search-border;
    padding-top: @nav-search-padding-vertical;
    padding-bottom: @nav-search-padding-vertical;

    &:hover { color: @nav-search-hover-color; }

}


// Active
.hook-nav-search-active() {}

//
// Sub-object: `uk-nav-header`
//

.hook-nav-search-header() {}

//
// Sub-object: `uk-nav-divider`
//

.hook-nav-search-divider() {}


// Search in offcanvas
// ==========================================================================

.hook-offcanvas-search-field() {
    border-bottom: 1px solid @offcanvas-search-field-border !important;
    padding: 0 @search-padding;
    cursor: text;
}


// Miscellaneous
// ========================================================================

.hook-search-misc() {

    // Sub-object `uk-search-field`
    // ========================================================================

    .uk-search-field:focus,
    .uk-search.uk-active .uk-search-field {
        padding: 0 @search-padding;
        cursor: text;
        border-bottom: none;
    }


    // Modifier: `uk-nav-search`
    // ========================================================================

    .uk-nav-search > li:first-child > a { border-top: none; }

}