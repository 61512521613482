// Name:            Tooltip
// Description:     Defines styles for tooltips
//
// Component:       `uk-tooltip`
//
// Modifiers        `uk-tooltip-top`
//                  `uk-tooltip-top-left`
//                  `uk-tooltip-top-right`
//                  `uk-tooltip-bottom`
//                  `uk-tooltip-bottom-left`
//                  `uk-tooltip-bottom-right`
//                  `uk-tooltip-left`
//                  `uk-tooltip-right`
//
// ========================================================================


// Variables
// ========================================================================

@tooltip-z-index:                               1030;
@tooltip-max-width:                             200px;
@tooltip-padding-vertical:                      5px;
@tooltip-padding-horizontal:                    8px;
@tooltip-background:                            #333;
@tooltip-triangle-size:                         5px;

@tooltip-color:                                 rgba(255,255,255,0.7);
@tooltip-font-size:                             12px;
@tooltip-line-height:                           18px;

@tooltip-offset-horizontal:                     10px;


/* ========================================================================
   Component: Tooltip
 ========================================================================== */

/*
 * 1. Hide by default
 * 2. Set fixed position
 * 3. Set dimensions
 * 4. Set style
 */

.uk-tooltip {
    /* 1 */
    display: none;
    /* 2 */
    position: absolute;
    z-index: @tooltip-z-index;
    /* 3 */
    box-sizing: border-box;
    max-width: @tooltip-max-width;
    padding: @tooltip-padding-vertical @tooltip-padding-horizontal;
    /* 4 */
    background: @tooltip-background;
    color: @tooltip-color;
    font-size: @tooltip-font-size;
    line-height: @tooltip-line-height;
    .hook-tooltip;
}


/* Triangle
 ========================================================================== */

/*
 * 1. Dashed is less antialised than solid
 */

.uk-tooltip:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    /* 1 */
    border: @tooltip-triangle-size dashed @tooltip-background;
}


/* Direction modifiers
 ========================================================================== */

/*
 * Top
 */

.uk-tooltip-top:after,
.uk-tooltip-top-left:after,
.uk-tooltip-top-right:after {
    bottom: -@tooltip-triangle-size;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: @tooltip-background;
}

/*
 * Bottom
 */

.uk-tooltip-bottom:after,
.uk-tooltip-bottom-left:after,
.uk-tooltip-bottom-right:after {
    top: -@tooltip-triangle-size;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: @tooltip-background;
}

/*
 * Top/Bottom center
 */

.uk-tooltip-top:after,
.uk-tooltip-bottom:after {
    left: 50%;
    margin-left: -@tooltip-triangle-size;
}

/*
 * Top/Bottom left
 */

.uk-tooltip-top-left:after,
.uk-tooltip-bottom-left:after { left: @tooltip-offset-horizontal; }

/*
 * Top/Bottom right
 */

.uk-tooltip-top-right:after,
.uk-tooltip-bottom-right:after { right: @tooltip-offset-horizontal; }

/*
 * Left
 */

.uk-tooltip-left:after {
    right: -@tooltip-triangle-size;
    top: 50%;
    margin-top: -@tooltip-triangle-size;
    border-left-style: solid;
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: @tooltip-background;
}

/*
 * Right
 */

.uk-tooltip-right:after {
    left: -@tooltip-triangle-size;
    top: 50%;
    margin-top: -@tooltip-triangle-size;
    border-right-style: solid;
    border-left: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: @tooltip-background;
}


// Hooks
// ========================================================================

.hook-tooltip-misc;

.hook-tooltip() {}
.hook-tooltip-misc() {}
