/* Custom variables and mixins */

@font-weight-bold: 500; // was 600 for Open Sans

dt, strong, b, .uk-text-bold { font-weight: @font-weight-bold; }

.uk-text-break {
	/* word-wrap: break-word doesn't work in chrome */
	word-break: break-word; /* but this works */
}

/* Custom html elements - FOUC prevention, see http://www.html5rocks.com/en/tutorials/webcomponents/customelements/ */

.uk-form:not(:defined) {
	display: block;
}

/* Preventing content to break outside main container */

.tm-content {
	overflow: hidden;
}

/* Excess margins and paddings */

p:last-of-type {
	margin-bottom: 0;
}

.uk-button-group {
	.uk-button.uk-button-large {
		padding: 0 16px;
	}
}

/* Missed utility styles */

.uk-pos-relative {
	position: relative;
}

.uk-vertical-align-baseline {
	vertical-align: baseline;
}

@media (min-width: @breakpoint-large) {

	.uk-margin-top-remove-on-large {
		margin-top: 0 !important;
	}
}

/* Alerts and notifications */

.uk-notify-message,
.uk-alert {
	i[class*='uk-icon-'] + span {
		padding-left: 5px;
	}
}

/* Forms */

.uk-form {
	label.uk-form-row {
		display: block;
	}
}

.uk-form-row.uk-form-icon {
	display: block;
}

.uk-form-icon>.wpf-icon-button[class*=uk-icon-] {
	display: block;
	cursor: pointer;
	pointer-events: auto;
	font-size: 125%;
	margin-top: round(1.25 * @form-icon-font-size / -2); // fix vertical position for bigger font site
}

/* Prevent FOUC while initializing conditional Vue elements */

.wpf-hide-not-ready-ifs {
	[v-if],
	[v-else],
	[v-else-if],
	[v-show],
	[v-for] {
		&:not(.wpf-dont-hide) {
			display: none;
		}
	}
}
[v-cloak] {
	display: none;
}

/* Validated form fields */

form.wpf-show-errors {
	input.invalid,
	select.invalid,
	textarea.invalid {
		.uk-form-danger();
		& + .uk-form-help-block {
			color: @form-danger-color !important;
		}
	}
}

/* CSS tables using display: table/table-row/table-cell */

.uk-table.wpf-table {
	display: table;
	.wpf-table-thead {
		display: table-header-group;
	}
	.wpf-table-tbody {
		display: table-row-group;
	}
	.wpf-table-tr {
		display: table-row;
	}
	.wpf-table-th {
		display: table-cell;
		font-weight: @font-weight-bold;
	}
	.wpf-table-td {
		display: table-cell;
	}
}

.uk-table .wpf-table-th,
.uk-table .wpf-table-td {
	padding: @table-padding-vertical @table-padding-horizontal;
}

.uk-table .wpf-table-th { text-align: left; }
.uk-table .wpf-table-td { vertical-align: top; }

.uk-table .wpf-table-thead .wpf-table-th { vertical-align: bottom; }

.uk-table-middle .wpf-table-td { vertical-align: middle !important; }

.uk-table-striped .wpf-table-tbody .wpf-table-tr:nth-of-type(odd) { background: @table-striped-background; }

.uk-table-condensed .wpf-table-td { padding: @table-condensed-padding-vertical @table-condensed-padding-horizontal; }

.uk-table-hover .wpf-table-tbody .wpf-table-tr:hover { background: @table-hover-background; }

/* Responsive table, stackable on mini screens */

@media (max-width: @breakpoint-mini-max) {

	.wpf-table.wpf-table-stacked-on-mini {
		.wpf-table-thead {
			display: none;
		}
		.wpf-table-tbody,
		.wpf-table-tr,
		.wpf-table-th,
		.wpf-table-td {
			display: block;
		}
	}

}

/* Minimal markup table */

.wpf-form {
	.wpf-form-field {
		display: block;
		&>span,
		&>label {
			display: block;
		}
		&>span+input,
		&>span+select,
		&>span+textarea {
			margin-top: 5px;
		}
	}
}

@media (max-width: @breakpoint-mini-max) {
	.wpf-form {
		.uk-width-1-1.uk-width-small-1-2.wpf-form-field+.wpf-form-field {
			margin-top: 15px;
		}
	}
}

/* Various utility elements */

@media (max-width: @breakpoint-mini-max) {

	.wpf-text-small-on-mini {
		.uk-text-small();
	}

}

.wpf-border-top {
	border-top: 1px solid @global-border;
}

.wpf-border-bottom {
	border-bottom: 1px solid @global-border;
}

/* Source ordering for `uk-width-small-*` */

@media (min-width: @breakpoint-small) {

	[class*='uk-small-push-'],
	[class*='uk-small-pull-'] { position: relative; }

	/* Push */

	/* Halves */
	.uk-small-push-1-2,
	.uk-small-push-2-4,
	.uk-small-push-3-6,
	.uk-small-push-5-10 { left: 50%; }

	/* Thirds */
	.uk-small-push-1-3,
	.uk-small-push-2-6 { left: 33.333%; }
	.uk-small-push-2-3,
	.uk-small-push-4-6 { left: 66.666%; }

	/* Quarters */
	.uk-small-push-1-4 { left: 25%; }
	.uk-small-push-3-4 { left: 75%; }

	/* Fifths */
	.uk-small-push-1-5,
	.uk-small-push-2-10 { left: 20%; }
	.uk-small-push-2-5,
	.uk-small-push-4-10 { left: 40%; }
	.uk-small-push-3-5,
	.uk-small-push-6-10 { left: 60%; }
	.uk-small-push-4-5,
	.uk-small-push-8-10 { left: 80%; }

	/* Sixths */
	.uk-small-push-1-6 { left: 16.666%; }
	.uk-small-push-5-6 { left: 83.333%; }

	/* Tenths */
	.uk-small-push-1-10 { left: 10%; }
	.uk-small-push-3-10 { left: 30%; }
	.uk-small-push-7-10 { left: 70%; }
	.uk-small-push-9-10 { left: 90%; }

	/* Pull */

	/* Halves */
	.uk-small-pull-1-2,
	.uk-small-pull-2-4,
	.uk-small-pull-3-6,
	.uk-small-pull-5-10 { left: -50%; }

	/* Thirds */
	.uk-small-pull-1-3,
	.uk-small-pull-2-6 { left: -33.333%; }
	.uk-small-pull-2-3,
	.uk-small-pull-4-6 { left: -66.666%; }

	/* Quarters */
	.uk-small-pull-1-4 { left: -25%; }
	.uk-small-pull-3-4 { left: -75%; }

	/* Fifths */
	.uk-small-pull-1-5,
	.uk-small-pull-2-10 { left: -20%; }
	.uk-small-pull-2-5,
	.uk-small-pull-4-10 { left: -40%; }
	.uk-small-pull-3-5,
	.uk-small-pull-6-10 { left: -60%; }
	.uk-small-pull-4-5,
	.uk-small-pull-8-10 { left: -80%; }

	/* Sixths */
	.uk-small-pull-1-6 { left: -16.666%; }
	.uk-small-pull-5-6 { left: -83.333%; }

	/* Tenths */
	.uk-small-pull-1-10 { left: -10%; }
	.uk-small-pull-3-10 { left: -30%; }
	.uk-small-pull-7-10 { left: -70%; }
	.uk-small-pull-9-10 { left: -90%; }

}

/* Pure CSS multiline text with ellipsis */

.multiline-ellipsis(@lines, @font-size, @line-height) {
	display: block; /* Fallback for non-webkit */
	display: -webkit-box;
	max-height: @lines * @font-size * @line-height; /* Fallback for non-webkit */
	-webkit-line-clamp: @lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Responsive embeds (useful for trimming images to certain aspect ration too)
   Source: https://getbootstrap.com/docs/4.0/utilities/embed/ */

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;

	&::before {
		display: block;
		content: "";
	}

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

.embed-responsive-21by9 {
	&::before {
		padding-top: percentage(9 / 21);
	}
}

.embed-responsive-16by9 {
	&::before {
		padding-top: percentage(9 / 16);
	}
}

.embed-responsive-4by3 {
	&::before {
		padding-top: percentage(3 / 4);
	}
}

.embed-responsive-1by1 {
	&::before {
		padding-top: percentage(1 / 1);
	}
}

/* Cookie consent message */

.wpf-cookie-consent {
	&.uk-notify-message>.uk-close {
		visibility: visible;
	}
}

/* Icon mixin */

.uk-icon() {
	&:before {
		font-family: FontAwesome;
		font-weight: normal;
		font-style: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

/* Alerts like on https://www.halloweencostumes.com */

.uni-alert {
	clear: both;
	box-sizing: border-box;
	width: 100%;
	margin: 10px 0;
	padding: 15px;
	border-left: 25px solid;
	&.error {
		color: #a94442;
		border-color: #cc8588;
		background-color: #f2dede;
	}
	&.warn {
		color: #7a5920;
		border-color: #d9cca3;
		background-color: #fcf9e3;
	}
	&.success {
		color: #3c763d;
		border-color: #afbfa3;
		background-color: #dff0d8
	}
}
