// Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

//
// Component: Comment
//
// ========================================================================

// Variables
// ========================================================================

@comment-header-margin-bottom:                      @global-margin;
@comment-avatar-margin-right:                       @global-margin;
@comment-primary-padding:							30px;
@comment-primary-background:						#fafafa;
@comment-title-font-size:                           @global-font-size * 1.125; //** Rem-based sizes - 18px in base size
@comment-title-line-height:                         1.35; //** Relative to corresponding font size
@comment-meta-color:                                @global-muted-color;
@comment-meta-font-size:                            @global-font-size * 0.8125; //** Rem-based sizes - 13px in base size
@comment-meta-line-height:                          1.45; //** Relative to corresponding font size
@comment-list-margin-top:                           30px;

//
// New
//

@comment-padding-bottom:                            30px;
@comment-avatar-border-radius:                      50%;
@comment-border:                                    @global-border;


// Component
// ==========================================================================

.hook-comment() {
    padding-bottom: @comment-padding-bottom;
    border-bottom: 1px solid @comment-border;
}

// Sub-object `uk-comment-header`
// ==========================================================================

.hook-comment-header() { padding-bottom: 10px; }


// Sub-object `uk-comment-avatar`
// ==========================================================================

.hook-comment-avatar() { border-radius: @comment-avatar-border-radius; }


// Sub-object `uk-comment-title`
// ==========================================================================

.hook-comment-title() {}


// Sub-object `uk-comment-meta`
// ==========================================================================

.hook-comment-meta() {}


/* Sub-object `uk-comment-body`
 ========================================================================== */

.hook-comment-body() {}


/* Modifier `uk-comment-primary`
 ========================================================================== */

.hook-comment-primary() {
    background: @comment-primary-background;
    padding: @comment-primary-padding;
}


// Miscellaneous
// ========================================================================

.hook-comment-misc() {}