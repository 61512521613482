// Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

//
// Component: Navbar
//
// ========================================================================

// Variables
// ========================================================================

@navbar-background:                             @global-background;
@navbar-color:                                  @global-color;
@navbar-link-color:                             @global-link-color;
@navbar-link-hover-color:                       @global-link-hover-color;
@navbar-nav-height:                             60px;
@navbar-nav-line-height:                        @navbar-nav-height;
@navbar-nav-padding-horizontal:                 15px;
@navbar-nav-color:                              @global-color;
@navbar-nav-font-size:                          @global-font-size;
@navbar-nav-font-family:                        'Open Sans';
@navbar-nav-hover-color:                        @global-color;
@navbar-nav-onclick-color:                      @global-color;
@navbar-nav-active-color:                       @global-color;
@navbar-nav-subtitle-font-size:                 @global-font-size * 0.875; //** Rem-based sizes - 14px in base size
@navbar-brand-font-size:                        @global-font-size * 2; //** Rem-based sizes - 32px in base size
@navbar-brand-color:                            @global-color;
@navbar-brand-hover-color:                      @global-color;
@navbar-toggle-font-size:                       @global-font-size * 1.25; //** Rem-based sizes - 20px in base size
@navbar-toggle-color:                           @global-color;
@navbar-toggle-hover-color:                     @global-color;

//
// New
//

@navbar-border-top-height:                      5px;
@navbar-border-top:                             @global-default-background;
@navbar-nav-border:                             @navbar-border;
@navbar-nav-border-top:                         @global-dark-background;
@navbar-gradient-top:                           #fdfdfd;
@navbar-gradient-bottom:                        #f8f8f8;
@navbar-nav-text-transform:                     uppercase;
@navbar-onclick-gradient-top:                   darken(@navbar-gradient-bottom, 3%);
@navbar-onclick-gradient-bottom:                darken(@navbar-gradient-top, 3%);
@navbar-border:                                 @global-border;
@navbar-box-shadow:                             @global-border;
@navbar-brand-horizontal-padding:               30px;


// Component
// ==========================================================================

.hook-navbar() {
    box-shadow: inset -1px 0 0 @navbar-border,
                inset 1px 0 0 @navbar-border;
    border-bottom: 1px solid @navbar-box-shadow;
}


// Sub-object: `uk-navbar-nav`
// ==========================================================================

.hook-navbar-nav() {
    text-transform: @navbar-nav-text-transform;
    border-left: 1px solid @navbar-border;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: -@navbar-border-top-height;
        right: 0;
        left: 0;
        height: @navbar-border-top-height;
        background: @navbar-nav-border-top;
    }

}


// Hover
.hook-navbar-nav-hover() {
    background: linear-gradient(to bottom, @navbar-gradient-top 20%, @navbar-gradient-bottom 100%);

    &:before {
        height: @navbar-border-top-height + 2px;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
}

// OnClick
.hook-navbar-nav-onclick() { background: linear-gradient(to bottom, @navbar-onclick-gradient-top 20%, @navbar-onclick-gradient-bottom 100%); }

// Active
.hook-navbar-nav-active() { background: linear-gradient(to bottom, @navbar-gradient-top 20%, @navbar-gradient-bottom 100%); }


// Sub-object: `uk-navbar-content`
// ==========================================================================

.hook-navbar-content() {}

// Miscellaneous
// ========================================================================

.hook-navbar-misc() {

    // Component
    // ==========================================================================

    .uk-navbar:before {
        display: block;
        height: @navbar-border-top-height;
        background: @navbar-border-top;
    }

    // Sub-object: `uk-navbar-nav`
    // ==========================================================================

    .uk-navbar-nav { border-right: 1px solid @navbar-border; }

    .uk-navbar-flip > .uk-navbar-nav,
    .uk-navbar:first-child > .uk-navbar-nav:first-child > li:first-child > a {
        margin-left: 1px;
        border: none;
    }

    .uk-navbar-nav + .uk-navbar-nav > li:first-child > a { border-left: none; }

    // Sub-object: `uk-navbar-brand`
    // ==========================================================================

    .uk-navbar-brand { padding: 0 @navbar-brand-horizontal-padding; }

}