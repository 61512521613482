// Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

//
// Component: Badge
//
// ========================================================================


// Variables
// ========================================================================

@badge-background:                              @global-transparent-background;
@badge-padding-horizontal:                      0;
@badge-font-size:                               @global-font-size * 0.875; //** Rem-based sizes - 14px in base size
@badge-font-weight:                             normal;
@badge-line-height:                             1.75; //** Relative to corresponding font size
@badge-color:                                   @global-link-color;
@badge-notification-font-size:                  @global-font-size * 0.8125; //** Rem-based sizes - 13px in base size
@badge-notification-line-height:                1.5em; //** Relative to corresponding font size (em - as it's used in min-width)
@badge-success-background:                      @global-transparent-background;
@badge-warning-background:                      @global-transparent-background;
@badge-danger-background:                       @global-transparent-background;

//
// New
//

@badge-padding-vertical:                        0;
@badge-border-height:                           4px;
@badge-notification-background:                 @global-default-background;
@badge-notification-color:                      @global-contrast-color;
@badge-success-color:                           @global-success-background;
@badge-success-border:                          @global-success-background;
@badge-warning-color:                           @global-warning-background;
@badge-warning-border:                          @global-warning-background;
@badge-danger-color:                            @global-danger-background;
@badge-danger-border:                           @global-danger-background;
@badge-border:                                  @global-default-background;


// Component
// ==========================================================================

.hook-badge() {
    padding: @badge-padding-vertical @badge-padding-horizontal;
    border-bottom: @badge-border-height solid @badge-border;
}

// Color modifier
// ==========================================================================

//
// Modifier: `bagde-success`
//

.hook-badge-success() {
    color: @badge-success-color;
    border-bottom-color: @badge-success-border;
}

//
// Modifier: `badge-warning`
//

.hook-badge-warning() {
    color: @badge-warning-color;
    border-bottom-color: @badge-warning-border;
}

//
// Modifier: `badge-danger`
//

.hook-badge-danger() {
    color: @badge-danger-color;
    border-bottom-color: @badge-danger-border;
}

// Miscellaneous
// ========================================================================

.hook-badge-misc() {

    // Modifier: `uk-badge-notification`
    // ====================================================================

    .uk-badge.uk-badge-notification {
        padding: 0 5px;
        border-bottom: none;
        background: @badge-notification-background;
        color: @badge-notification-color;
    }

    .uk-badge-notification.uk-badge-success { background: @badge-success-border; }
    .uk-badge-notification.uk-badge-warning { background: @badge-warning-border; }
    .uk-badge-notification.uk-badge-danger { background: @badge-danger-border; }

}