// Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

//
// Component: Form
//
// ========================================================================

// Variables
// ========================================================================

@form-height:                               35px;
@form-small-height:                         @global-small-height;
@form-large-height:                         @global-large-height;
@form-small-font-size:                      @global-font-size * 0.875; //** Rem-based sizes - 14px in base size
@form-large-font-size:                      @global-font-size * 1.125; //** Rem-based sizes - 18px in base size
@form-border:                               @global-border;
@form-background:                           @global-background;
@form-color:                                @global-color;
@form-placeholder-color:                    @global-muted-color;
@form-focus-border:                         fade(darken(@global-default-background, 40%), 30%);
@form-focus-background:                     fade(@global-default-background, 8%); //** More transparent to easier distinction from fields with errors
@form-focus-color:                          @global-color;
@form-disabled-border:                      @global-border;
@form-disabled-color:                       @global-muted-color;
@form-danger-border:                        fade(@global-danger-background, 60%);
@form-danger-background:                    fade(@global-danger-background, 30%);
@form-danger-color:                         darken(@global-danger-background, 10%);
@form-success-border:                       fade(@global-success-background, 90%);
@form-success-background:                   fade(@global-success-background, 30%);
@form-success-color:                        darken(@global-success-background, 20%);
@form-blank-border:                        @global-border;
@form-legend-border:                        @global-border;
@form-legend-font-size:                     @global-font-size * 1.25; //** Rem-based sizes - 20px in base size
@form-legend-line-height:                   1.625;
@form-gutter:                               @global-margin;
@form-icon-width:                           @global-height;
@form-icon-font-size:                       @global-font-size;
@form-icon-color:                           @global-muted-color;

//
// New
//

@form-border-radius:                        @global-border-radius;


// Component
// ==========================================================================

.hook-form() { border-radius: @form-border-radius; }

// Focus state
.hook-form-focus() {}

// Disabled state
.hook-form-disabled() {}

// Legend
.hook-form-legend() {}

// Sub-object: `uk-form-label`
// ========================================================================

.hook-form-stacked-label() {}

.hook-form-horizontal-label() {}


// Validation states
// ==========================================================================

//
// Error state
//

.hook-form-danger() {}

//
// Success state
//

.hook-form-success() {}