// Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

//
// Component: Tooltip
//
// ========================================================================

// Variables
// ========================================================================

@tooltip-z-index:                           (@global-z-index + 30);
@tooltip-font-size:                         @global-font-size * 0.8125; //** Rem-based sizes - 13px in base size
@tooltip-line-height:                       1.5; //** Relative to corresponding font size

//
// New
//

@tooltip-border-radius:                     @global-border-radius;


// Component
// ==========================================================================

.hook-tooltip() { border-radius: @tooltip-border-radius; }